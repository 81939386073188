import React from 'react';
import {Snackbar, IconButton} from '@material-ui/core';
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";

export default function Toasty({
    isOpen = PropTypes.bool.isRequired,
    handleClose = PropTypes.func.isRequired,
    message = PropTypes.string
}) {

  // React.useEffect(()=>{
  //   // console.log('t');
  //     // setOpen(props.open);
  // });


  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isOpen}
        // autoHideDuration={4000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
          
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
