import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { dataProvider } from "../util";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useStyles } from "../styles";
import PropTypes from "prop-types";
import AlertDialog from "../components/AlertDialog";
import { Grid, Typography } from "@material-ui/core";
import Toast from "../components/Toast";

// moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

export default function BookCalendar({
  data,
  dateSelect = PropTypes.func.isRequired,

  // events = PropTypes.array.isRequired,
}) {
  const classes = useStyles();
  const [booktime, setBookTime] = useState({});
  const [user, setUser] = useState({});

  const [booktimeStart, setBookTimeStart] = useState({ book_start: null });
  const [booktimeEnd, setBookTimeEnd] = useState({ book_end: null });
  const [bookData, setBookData] = useState([]);

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  //AlertDialog ----------------------------------------
  const [dialogDom, setDialogDom] = useState({
    childrenData: "",
    title: "",
    subtitle: "",
  });
  const [dialogState, setDialogState] = useState("save");
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    // fetchBooked();
    setIsOpen(false);
    setDialogDom({});
    setBookTime({});
    
  };
  const handleDialogAgree = () => {
    if (dialogState === "save") {
      let start = new Date(dialogDom.title + " " + booktime.book_start);
      let end = new Date(dialogDom.title + " " + booktime.book_end);
      console.log(dialogState);
      dateSelect({
        book_start: moment(start).format("YYYY-MM-DD HH:mm"),
        book_end: moment(end).format("YYYY-MM-DD HH:mm"),
      }).then(res => {
        if(res){
          //added]
          setToastMessage("Booking date set to "+dialogDom.title);
        setIsToastOpen(true);
        setIsOpen(false);
        }
      })
    }
    if (dialogState === "update") {
      updateBooking().then((res) => {
        if(res){
          fetchBooked();
          setIsOpen(false);
        }
      })
    }

    // setIsOpen(false);
  };

  const updateBooking = async () => {
    console.log(booktime)
    // return;
    let m_start = moment(booktime.book_date+" "+booktime.book_start);
    let m_end = moment(booktime.book_date+" "+booktime.book_end);
    //conditions for time

    if(moment().unix() > m_start.unix() || moment().unix() > m_end.unix() ||(m_start.unix() > m_end.unix())){
      setToastMessage("Invalid dates");
      setIsToastOpen(true);
      return false;
    }
    try {
//       let start = new Date(dialogDom.title + " " + booktime.book_start);
//       let end = new Date(dialogDom.title + " " + booktime.book_end);
// console.log(start, "---");
      const mydata = await dataProvider("put", "/booking-date", {
        id: user.id,
        book_start: m_start.format("YYYY-MM-DD HH:mm"),
        book_end: m_end.format("YYYY-MM-DD HH:mm"),
        status: "booked",
      });
      
      if (mydata.status !== 200) {
        console.log(mydata, "ERR");
        setToastMessage(mydata?.message);
        setIsToastOpen(true);
        return false;
      }


      // fetchBooked();
      setToastMessage(mydata?.data.message);
      setIsToastOpen(true);
      return true;
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
      return false;
    }
  };
  const handleDialogCancel = () => {
    setIsOpen(false);
    // fetchBooked();
  };
  // --------------------------------------------------

  const handleBookTime = (event) => {
    // console.log(event.target.name)

    setBookTime((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.toLocaleString("en-JM", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    }));
  };

  const fetchBooked = async () => {
    try {
      const mydata = await dataProvider("post", "/bookings-fetch", {
        status: "booked",
      });
      if (mydata.status !== 200) {
        console.log(mydata, "ERR");
        // setToastMessage(mydata?.message);
        // setIsToastOpen(true);
        // return false;
      }

      mydata.data = (mydata.data || []).map((item) => {
        item["start"] = item["start"] && moment(item["start"]).toDate();
        item["end"] = item["end"] && moment(item["end"]).toDate();
        return item;
      });

      setBookData(mydata.data);
      // console.log(mydata, "mydata2");
      //  return (mydata.data);
      // EVENTS = useMemo(mydata.data)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    fetchBooked();
  }, []);

  const bookForm = (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}></div>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle">From</Typography>
        </Grid>
        <Grid item xs={6}>
          <input
            type="time"
            name="book_start"
            value={booktime?.book_start}
            onChange={handleBookTime}
          />
        </Grid>
        <Grid item xs={6} style={{ marginTop: 20 }}>
          <Typography variant="subtitle">To</Typography>
        </Grid>
        <Grid item xs={6} style={{ marginTop: 20 }}>
          <input
            type="time"
            name="book_end"
            value={booktime?.book_end}
            onChange={handleBookTime}
          />
        </Grid>
      </Grid>
    </div>
  );

  const upBookForm = (
    <div>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}></div>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <Typography variant="subtitle">Date</Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <input
              type="date"
              name="book_date"
              value={booktime?.book_date}
              onChange={handleBookTime}
            />
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <Typography variant="subtitle">From</Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <input
              type="time"
              name="book_start"
              value={booktime?.book_start}
              onChange={handleBookTime}
            />
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <Typography variant="subtitle">To</Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <input
              type="time"
              name="book_end"
              value={booktime?.book_end}
              onChange={handleBookTime}
            />
          </Grid>
        </Grid>
      </div>
  );

  const onEventSelect = (e) => {

    console.log(e);
    setDialogDom({});
    setBookTime({});
    setUser(e);
    setDialogState("update");

    //set selected event time
    let start = moment(e.start).format("HH:mm");
    let end = moment(e.end).format("HH:mm");

    setBookTime({ book_start: start, book_end: end, book_date: moment(e.start).format("YYYY-MM-DD")});
    setDialogDom({    
      title: "Booked for " + moment(e.start).format("MMM DD h:mm A"),
      subtitle:
        e.first_name + " is booked for; " + JSON.parse(e.service).join(),
      ok: "Save Changes",
      cancel: "Close",
      data:{date:{start:e.start, end:e.end}}
    });
    handleDialogOpen();
  };

  const selectDate = (e) => {
    if(dateSelect === null) return false;
    setDialogState("save");
    setDialogDom({});
    setBookTime({});
    setDialogDom({
      childrenData: bookForm,
      title: e.start.toString().substring(0, 15),
      subtitle: "Booking period will be",
      ok: "OK",
      cancel: "Cancel",
    });
    handleDialogOpen();
  };

  const onEventResize = (data) => {
    console.log(data, "resize");
    // const { start, end } = data;

    // setState((state) => {
    //   state.events[0].start = start;
    //   state.events[0].end = end;
    //   return { events: [...state.events] };
    // });
  };
  const onEventDrop = (data) => {
    console.log("drop", data);
  };

  return (
    <div className={classes.root} style={{ paddingTop: 10 }}>
      {bookData ? (
        <Calendar
          selectable
          defaultDate={moment().toDate()}
          defaultView="month"
          events={bookData}
          localizer={localizer}
          onSelectEvent={onEventSelect}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          resizable
          style={{ height: "100vh" }}
          onSelectSlot={selectDate}
        />
      ) : (
        "--"
      )}

      {/* <div>
        {booktime?.book_start && booktime?.book_end ? (
          <p>
            You have selected a booking for <b>{dialogDom.title}</b>
          </p>
        ) : (
          ""
        )}
      </div> */}

      <AlertDialog
        isOpen={isOpen}
        handleClose={handleDialogClose}
        handleAgree={handleDialogAgree}
        handleCancel={handleDialogCancel}
        title={dialogDom.title}
        subtitle={dialogDom.subtitle}
        ok={dialogDom.ok}
        cancel={dialogDom.cancel}
        children={dialogState === 'save'?(
          bookForm
        ) :upBookForm}
        // children={bookForm}
      />
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
