import React from "react";
import BookCalendar from "../components/BookCalendar"
import {useStyles} from "../styles"

export default function ViewCalendar(){
  const classes = useStyles();
    return (
      <div className={classes.root}>
         <BookCalendar dateSelect={null} />
        </div>
    )
}