import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: 15,
      paddingTop: 100,
    //   paddingTop: 100,
      backgroundColor: "#F4F6F8",
    },
    formRoot: {
      "& > *": {},
    },
    flow: {
      width: "100%",
    },
    icon: {
      color: "red",
    },
    section1: {
      marginTop: 20,
    },
    subTitle: {
      color: "#0635C9",
      textAlign: "left",
      fontSize: "1.2em",
    },
    paper: {
      position: "absolute",
      width: "auto",
      maxHeight: "100vh",
      top: 20,
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #666",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: "scroll",
    },
    newpaper: {
      // position: "absolute",
      width: "auto",
      maxHeight: "100vh",
      // top: 20,
      textAlign: "left",
      backgroundColor: theme.palette.background.paper,
      // border: "1px solid #aaa",
      // boxShadow: theme.shadows[3],
      padding: theme.spacing(2, 3, 2),
      // overflow: "scroll",
    },
    hint:{
      color:'#aaaaaa'
    },
    hint2:{
      color:'#000',
      fontWeight:700
    }
  }));