import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import MaskText from "../components/MaskText";
import Toast from "../components/Toast";
import {useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
// import service_icon from "../car-service.png";
import icon from "../box.png";
import { dataProvider } from "../util";
import AlertDialog from "../components/AlertDialog";

// import * as Scroll from 'react-scroll';
import { scroller } from "react-scroll";

// var scroll = animateScroll;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    // background: "#f3f3f3"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function AddCustomer() {
    let history = useHistory();
  const classes = useStyles();
  // const [service, setService] = React.useState({});
  const [customer, setCustomer] = React.useState({});

  // const [serviceList, setServiceList] = React.useState([]);
  // const [agree, setAgree] = React.useState(false);
  const form = useRef();

  //AlertDialog ----------------------------------------
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -90,
    });
  };
  const handleDialogAgree = () => {

   
    if (!customer.title || customer.title.length < 1) {
      doToast("Title should be atleast 1 character");
      scrolly("title");
      return false;
    }
   
    if (!customer.title || customer.title.length < 1) {
      doToast("Description should be atleast 1 character");
      scrolly("description");
      return false;
    }
   
    if (!customer.price || customer.price.length < 1) {
      doToast("Price should be atleast 1 character");
      scrolly("title");
      return false;
    }
   

    //postCustomer(customer)
    dataProvider('post', '/product', customer)
      .then((res) => {
        // console.log(res.status," RES")
        if(res.message === 'Network Error') throw new Error(res.message);
        setToastMessage(res.data.message);
        setIsToastOpen(true);

        if(res.data.value === true){
            setTimeout(() => {
          reset();
  
        }, 0);
        }
        
      })
      .catch((error) => {
     console.log(error, "post")
        setToastMessage(error.message);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  };


  const reset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setCustomer({});
  };

  const doToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };
  const handleDialogCancel = () => {
    // window.alert('cancel');
    setIsOpen(false);
  };
  // -----------------------------------------------------

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  // const handleChange = (event) => {
  //   // if(event.target.checked === true) {
  //   setService({ ...service, [event.target.name]: event.target.checked });
  //   // }
  // };

  const handleCustomerChange = (event) => {
    setCustomer({ ...customer, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    scrolly("pageTop");
  }, []);

  return (
    <div className={classes.root} id="pageTop">
      <form className={classes.formRoot} autoComplete="off" ref={form}>
        <Grid container spacing={3} style={{ paddingTop: 30 }}>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
 
                  <Typography
                    gutterBottom
                    variant="h2"
                    style={{ fontSize: "2em" }}
                  >
                   <b>Add</b> Product
                  </Typography>
                </Grid>
                {/* <Grid item>1
                  <Typography gutterBottom variant="h6">
                    $4.50
                  </Typography>
                </Grid> */}
              </Grid>

              <Divider
                style={{
                  marginTop: 15,
                  background: "#f1f1f1",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {/* <ServiceIcon fontSize="large" className={classes.icon}/> */}
                  <img alt="product" src={icon} width={60} />
                  <Typography gutterBottom variant="h5">
                    Product details
                  </Typography>
                </Grid>
                {/* <Grid item>
            <Typography gutterBottom variant="h6">
              $4.50
            </Typography>
          </Grid> */}
              </Grid>
              <Typography color="textSecondary" variant="body2">
                Enter product details and save to invetory.
              </Typography>
              {/* <Divider style={{ marginTop: 15 }} /> */}
            </div>
          </Grid>

         
          <Grid item xs={12} md={6}>
            <TextField
              label="Title"
              name="title"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Description"
              name="description"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Price"
              name="price"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              // InputProps={{
              //   inputComponent: MaskText,
              // }}
              type="number"
              // onInput={(e) => {
              //   e.target.value = parseFloat(e.target.value).toFixed(2)
              // }}
              inputProps={{ maxLength: 11 }}
              autoComplete="off"
            />
          </Grid>  
        </Grid>

        <Grid container spacing={3}>
           <Grid item xs={12} style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              size="large"
              onClick={reset}
              style={{ marginRight: 20 }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleDialogAgree}
            //   type="submit"
            >
              Save
            </Button>


          </Grid>
        </Grid>
      </form>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
