import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { checkAuth, getProfile } from "../util";
import Footer from "./Footer";
const PrivateRoute = ({
  component: Component,
  permission: permission,
  ...rest
}) => {
  const [profile, setProfile] = React.useState({});
  // username: "", permission: 0 
  useEffect(() => {
    loadProfile();
    // console.log(Component);
    // console.log(permission)
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => (
        // profile.permission < permission?(<Redirect to="/" />):(<React.Fragment></React.Fragment>)

        <React.Fragment>
          {profile?.permission < permission ? (
            <Redirect to="/" />
          ) : (
            <div>.</div>
          )}
          {checkAuth() === true ? (
            <React.Fragment>
              <Component {...props} />
              <Footer />
            </React.Fragment>
          ) : (
            <Redirect to="/login" />
          )}
        </React.Fragment>
      )}
    />
  );
};

export default PrivateRoute;
