import React from 'react';
import {Snackbar} from '@material-ui/core';
import PropTypes from "prop-types";

export default function Toast({
    isOpen = PropTypes.bool.isRequired,
    handleClose = PropTypes.func.isRequired,
    message = PropTypes.string
}) {

  // React.useEffect(()=>{
  //   // console.log('t');
  //     // setOpen(props.open);
  // });


  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        message={message}
        // action={
        //   <React.Fragment>
        //     <Button color="secondary" size="small" onClick={handleClose}>
        //       UNDO
        //     </Button>
        //     <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        //       <CloseIcon fontSize="small" />
        //     </IconButton>
        //   </React.Fragment>
        // }
      />
    </div>
  );
}
