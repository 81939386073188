import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {checkAuth} from '../util';
import Footer from "./Footer";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            checkAuth() && restricted ?
                <Redirect to="/" />
            :  <React.Fragment><Component {...props} /><Footer/></React.Fragment>
        )} />
    );
};

export default PublicRoute;