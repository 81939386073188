import React  from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";

export default function AlertDialog({
    isOpen = PropTypes.bool.isRequired,
    handleClose = PropTypes.func.isRequired,
    handleCancel = PropTypes.func,
    handleAgree = PropTypes.func.isRequired,
    title = PropTypes.string.isRequired,
    subtitle = PropTypes.string,
    ok = null,
    cancel = null,
    children = PropTypes.element,
}) {

//   useEffect(()=>{
//       setOpen(props.open);
//   },[props.open]);


  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subtitle}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {cancel?cancel:"Cancel"}
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
          {(ok)?ok:"Agree"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}